import React from "react"

import Layout from "../components/layout/index"
import Banner from "../components/home/banner/index"
import About from '../components/about/about/index'
import Address from '../components/about/address/index'
// import Image from "../components/image"
import SEO from "../components/seo"

// const Firends = ()=>{
//   return <></>
// }

const AboutPage = () => {
  const bannerJson ={
    title:'联每户',
    subTitle:'美好生活缔造者'
  }
  return(
  <Layout page="About">
    <SEO title="联每户" />
    <Banner json={bannerJson} bannerUrl={
        "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/5c56d7e0d2754ef0acd2e13af0d5e136"
      }
      imgLogo={
        "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/April/2/21c4af83d17544439e55f751ab39b054"
      }/>
    <About />
    <Address />
  </Layout>
  )
}

export default AboutPage
