import React, { useEffect } from "react"
import styles from "./index.module.css"
import QQMap from 'qqmap'

const Address = () => {
  //地图初始化方法
  const initQQMap = () => {
    //console.log('初始化QQ 地图')
    //设置中心坐标
    let tarLat = 30.275638;
    let tarLng = 119.997054;
    //初始化地图
    QQMap.init('7YWBZ-OE26J-DPPF7-KYWVL-QBZBJ-F7FLC', () => {
      //初始化经纬度，最开始的点
      let myLatlng = new window.qq.maps.LatLng(tarLat, tarLng);
      //设置地图属性
      let myOptions = {
        zoom: 15,
        center: myLatlng,
        mapStyleId: 'style 1 经典',
        mapTypeId: window.qq.maps.MapTypeId.ROADMAP,
      };
      //创建地图，绑定dom
      const map = new window.qq.maps.Map(
        document.getElementById('address-container'),
        myOptions
      );
      //给定位的位置添加文本标注


      new window.qq.maps.Label({
        position: myLatlng,
        map: map,
        content: '<div style="color: #1296db;position: absolute;left: -38PX;top:-80PX;border: 1PX solid #ccc;border-radius: 5PX;background: #fff;padding: 8PX;font-size:20PX">联每户</div>'
      });
      //给定位的位置添加图片标注
      new window.qq.maps.Marker({
        position: myLatlng,
        map: map
      });


    })



  }

  useEffect(() => {
    setTimeout(() => {

      initQQMap()
    }, 300)
    return () => {

    }
  }, [])
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.left} id='address-container'>

        </div>
        <div className={styles.right}>
          <div className={styles.title}>联系我们</div>
          <p>公司名称：杭州联每户网络科技有限公司</p>
          <p>公司地址：浙江省杭州市余杭区文一西路1288号海创科技中心1号楼1206</p>
          <p>联系方式：0571-87703081</p>
          <p>公司邮箱：18017110789@163.com</p>
        </div>
      </div>
    </div>
  )
}

export default Address
